import React from "react";
import PropTypes from "prop-types";
import { navigate, Link, graphql } from "gatsby";
import loadable from "@loadable/component";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";

import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import useWidth from "../hooks/useWidth";
import SEO from "../components/SEO";
import WhiteButton from "../components/v2/WhightButton";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CheckIcon from "@material-ui/icons/Check";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Hidden from "@material-ui/core/Hidden";

// All Images
import salesManagementImg from "../images/v2/home/features-images/sales-management.svg";
import integrationImg from "../images/v2/home/features-images/integration.svg";
import securityImg from "../images/v2/home/features-images/security.svg";
import webformsImg from "../images/v2/home/features-images/webforms.svg";
import analyticsAndInsites from "../images/v2/home/features-images/analytics-and-insites.svg";
import dashboardImg from "../images/v2/home/features-images/dashboard.svg";
import leadCapture from "../images/v2/home/features-images/lead-capture.svg";
import lightBlueBgImg from "../images/v2/light-blue-bg.svg";
import remoteReadyBG from "../images/v2/home/remote-ready-bg.svg";
import responsiveBG from "../images/v2/home/responsive-bg-img.svg";
import integrationBG from "../images/v2/home/integration-bg.svg";
import crmInsightsBgImg from "../images/v2/home/crm-insights-bg.svg";
import whatsappIcon from "../images/v2/campaigns/social.png";
import whatsappMessage from "../images/v2/home/features-images/what's-app-message.svg";
import SingleTestimonial from "../components/v2/HomeSlider/SingleTestimonial";
import smeSupportLogo from "../images/v2/campaigns/smeSupportLogo.png";
import CompanyCard from "../components/v2/CompanyCard";
import whatsAppChat from "../animations/whatsAppChat.json";
import LazyLoad from "react-lazyload";
import Lottie from "lottie-react";
import HomeCard from "../components/v2/HomeCard/HomeCard";
import tickImage from "../images/v2/whatsapp/tickIconImage.png";
// import WebinarModal from "../components/v2/WebinarModal";

const VideoModal = loadable(() => import("../components/v2/VideoModal"));

const useStyles = makeStyles((theme) => ({
  customHeader: {
    width: '376px',
    height: '56px',
    margin: '95px 512px 1px 63px',
    fontFamily: 'Poppins',
    fontSize: '40px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.5',
    letterSpacing: '0.8px',
    textAlign: 'left',
    color: '#0291ae'
  },
  homeSliderText: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  loginButton: {
    marginTop: theme.spacing(2),
    color: "#000000",
    border: "1px solid rgb(0 0 0 / 62%)",
    fontWeight: 600,
  },
   tickImages: {
    paddingLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      padding: "0",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      padding: "0",
    },
  },
  customButton: {
    fontSize: "22px",
  },
  explorButton: {
    boxShadow:
      " 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    border: "1px solid green",
    "&:hover": {
      border: "1px solid green",
    },
  },
  clientLogoImg: {
    width: "100%",
    height: "auto",
    padding: "0 1rem",
  },
  cycleProcessImg: {
    "& picture img": {
      willChange: "auto !important",
    },
    // maxWidth: "530px",
    // height: "auto",
  },
  img: {
    maxWidth: "100%",
    height: "auto",
  },
  imgLogo: {
    height: "86px",
    width: "86px",
    [theme.breakpoints.down("xs")]: {
      height: "40px",
      width: "40px",
    },
  },
  crmInsightsimg: {
    maxWidth: "480px",
    height: "auto",
  },
  crmheadimg: {
    width: "100%",
    height: "auto",
  },
  crmheadimgContainer: {
    marginBottom: "-45px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px",
    },
  },
  crmheadingsubimg: {
    textAlign: "center",
    marginBottom: "16px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px",
    },
  },
  featureItemContainer: {
    listStyleType: "none",
    padding: "0px",
  },
  featureItem: {
    display: "block",
    cursor: "pointer",
    border: "2px solid",
    borderRadius: theme.shape.borderRadius * 2.5,
    borderColor: "#e7f2ff",
    lineHeight: "22px",
    padding: theme.spacing(2),
    minHeight: "370px",
    "&:hover": {
      background: "#f2f9f9",
      borderColor: "#f2f9f9",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "380px",
      padding: "1rem",
      "& h3": {
        fontSize: "22px",
      },
    },
  },
  featureItemText: {
    fontSize: "16px !important",
  },
  remoteReadyRoot: {
    background: `url(${remoteReadyBG})`,
    backgroundSize: "cover",
    backgroundPosition: "bottom",
  },
  responsiveRoot: {
    background: `url(${responsiveBG}) center top`,
    backgroundSize: "cover",
    minHeight: "700px",
    paddingTop: "180px",
    [theme.breakpoints.down("sm")]: {
      backgroundImage:
        "linear-gradient(241deg, #00b8d8 100%, #00b8d8 53%, #0893af)",
      paddingTop: "32px",
    },
  },
  integrationRoot: {
    background: `url(${integrationBG})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginTop: theme.spacing(8),
    padding: theme.spacing(2),
  },
  collapseDivider: {
    backgroundColor: "#7ad2e2",
  },
  expendButton: {
    backgroundColor: "#5ab6cf",
    marginBottom: theme.spacing(1),
    "&:hover": {
      background: "#a1dae2",
    },
  },
  numberBorder: {
    borderRight: "1px solid #e5e7e9",
  },
  contactUsCard: {
    boxShadow: "0 15px 50px 0 rgba(118, 130, 183, 0.16)",
    padding: theme.spacing(3),
    borderRadius: 10,
    border: "solid 1px #e2f0ff",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.5),
    },
  },
  blogCard: {
    boxShadow: "0 15px 20px 0 rgba(118, 130, 183, 0.18)",
    borderRadius: 5,
    padding: theme.spacing(2),
    marginTop: -2,
  },
  IntegratedCRMBg: {
    background: "linear-gradaent(to bottom, #ffffff 0%, #e7f2fe 100%)",
  },
  ConnectCrm: {
    background: "linear-gradient(to bottom, #ffffff 0%, #e7f2fe 100%)",
  },
  WhatsAppCrm: {
    background: "linear-gradient(1deg, #dbe9f9 30%, #fff 117%)",
  },
  svg: {
    "& svg g g rect": {
      fill: "none",
    },
  },
  lightBlueBg: {
    background: `url(${lightBlueBgImg}) center top`,
  },
  crmInsightsBg: {
    // background: `url(${crmInsightsBgImg}) left top no-repeat`,
    background: "linear-gradient(179deg, #eaf4fe -6%, #fff 40%)",
  },
  iconContainer: {
    display: "flex",
    columnGap: "100px",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      columnGap: "10px",
      marginLeft: "29px",
      marginTop: "-64px",
    },
  },
  smeSupportImg: {
    width: "461.2px",
    height: "79.3px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "auto",
    },
  },
  formSubmit: {
    fontSize: "16px",
    fontWeight: "600",
  },
  listItemText: {
    "& *": {
      fontSize: 20,
      color: "#2e3f4f",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
  },
  listItemIcon: {
    color: "#fff",
    fontSize: 36,
    backgroundColor: "#08a742",
    borderRadius: "50%",
    padding: "5px",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
      marginRight: theme.spacing(1.5),
    },
  },
  sliderTestimonialImg: {
    boxShadow: "7px 20px 20px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: "10px",
  },
  cimg: {
    "& .gatsby-image-wrapper": {
      filter: "grayscale(100%)",
      "&:hover": {
        filter: "none",
      },
    },
  },
  link: {
    cursor: "pointer",
  },
  getStartedBtn: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
  videoContainer: {
    position: "relative",
  },
  "@keyframes smallScale": {
    from: {
      transform: "scale(1)",
      opacity: 1,
    },
    to: {
      transform: "scale(1.5)",
      opacity: 0,
    },
  },
  "@keyframes fadeIn": {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  "@keyframes triangleStroke": {
    from: {
      strokeDashoffset: 90,
    },
    to: {
      strokeDashoffset: 0,
    },
  },

  videoBtnCircle: {
    cursor: "pointer",
    position: "absolute",
    display: "flex",
    backgroundColor: "#4cbb83",
    borderRadius: "50%",
    width: "100px",
    height: "100px",
    padding: "0px",
    top: "62.5%",
    left: "35.5%",
    zIndex: "2",
    "&:before, &:after": {
      content: '""',
      background: "rgba(76, 187, 131, 0.5)",
      borderRadius: "50%",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      height: "100%",
      width: "100%",
      animationName: "$smallScale",
      animationDuration: "3s",
      animationTimingFunction: "linear",
      animationIterationCount: "infinite",
    },
    "&:after": {
      animationDelay: "0.5s",
      zIndex: -1,
    },
  },
  playButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "30px",
    height: "30px",
    transform: "translate(-50%, -50%)",
    "& .triangle": {
      animation: `$fadeIn 1.5s ${theme.transitions.easing.easeIn}`,
    },
    "& .path": {
      strokeDasharray: 90,
      strokeDashoffset: 0,
      animation: "$triangleStroke 1.5s",
      animationTimingFunction: `${theme.transitions.easing.easeIn}`,
    },
  },
  hasScaleAnimation: {
    animationName: "$smallScale",
    animationDuration: "3s",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
  },
  bold: {
    fontWeight: "700",
    color: "#333333",
  },
  TelegramIntegrationHeader: {
    width: 'auto',
    height: '56px',
    margin: '0 0 1px 63px',
    fontFamily: 'Poppins',
    fontSize: '40px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.5',
    letterSpacing: '0.8px',
    textAlign: 'left',
    color: '#0291ae',
  },
  syncAndSendHeader: {
    width: '701px',
    height: '31px',
    margin: '21px 295px 22px 63px',
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '2.73',
    letterSpacing: '0.44px',
    textAlign: 'left',
    color: '#000',
  },
  efficientlyManageTasks: {
    width: '780px',
    height: '65px',
    margin: '22px 125px 50px 63px',
    // fontFamily: 'SourceSansPro',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.56',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000',
  },
  TelegramIntegrationFeatures: {
    width: '400px',
    height: '31px',
    margin: '21px 295px 22px 63px',
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '2.73',
    letterSpacing: '0.44px',
    textAlign: 'left',
    color: '#000',
  },
  Parafeatures : {
    width: '792px',
    height: '201px',
    margin: '22px 125px 50px 63px',
    // fontFamily: 'SourceSansPro',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.56',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000',
  },
  customerCommunication: {
    width: '745px',
    height: '137px',
    margin: '22px 125px 50px 63px',
    //fontFamily: 'SourceSansPro',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '2.22',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000',
  },
  gmailIntegration: {
    width: '650px',
    height: '31px',
    margin: '21px 295px 22px 63px',
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '2.73',
    letterSpacing: '0.44px',
    textAlign: 'left',
    color: '#000',
  },
    loginto: {
    width: '546px',
    height: '332px',
    margin: '22px 125px 50px 63px',
    // fontFamily: 'SourceSansPro',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '2.22',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000',
  },
  NotUnderline : {
    width: '456px',
    height: '26px',
    marginLeft : '60px',
    // fontFamily: 'SourceSansPro',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.56',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000',
    display: 'inline',
    //  marginRight: theme.spacing(1),
  },
  Underline : {
    color: '#0f27c4',
    display : 'inline',
     textDecoration: 'underline',
  }
  // listItemTextWhite: {
  //   "& *": {
  //     fontSize: 20,
  //     color: "#fff",
  //     whiteSpace: "noWrap",
  //     [theme.breakpoints.down("sm")]: {
  //       fontSize: 18,
  //     },
  //     [theme.breakpoints.down("md")]: {
  //       fontSize: 15,
  //     },
  //     [theme.breakpoints.down("xs")]: {
  //       fontSize: 18,
  //     },
  //   },
  // },
  
  // Other styles...
}));



const listOne = `Share catalogs with ease
Send bulk promotions
Chat widget on website`.split(`
`);

const listTwo = `Auto-reply with message templates
Single number for team
Assist customers with AI chatbot`.split(`
`);

const listThree = `Facebook integration
Instagram integration
Telegram integration
Sales management
Sales forecasting`.split(`
`);

const listFour = `Lead  management
Workflow automation
Audio and video calls
Customised dashboard
Integrated with various tools`.split(`
`);

const listFive = `Up to 50% PSG grant
24/7 Local support
No hidden charges
Built for Singapore SMEs`.split(`
`);


const security =
  `Sync your communication channels and expedite sales with instant messaging.
Arm your team with the CRM system to win more opportunities with the least manual work.
Track all the tasks and associated activities and refocus their efforts when necessary. 
Instantly acknowledge the top performers of your team and derive the best practices for everyone.
Reason your won and lost opportunities by analysing your processes and sales strategies.`
    .split(`
`);

const CrmTelegramIntegration  = (props) => {
  const width = useWidth();
  const classes = useStyles();

  const [showVideo, setShowVideo] = React.useState(false);
  const [activePipeline, setActivePipeline] = React.useState(0);

  const pipeline = React.useMemo(() => {
    const {
      data: { featureEngage, featureManage, featureAutomate, featureExpand },
    } = props;
    const pipeline = [
      {
        title: "Engage",
        description: (
          <>
            Engage effectively with your prospects and customers by sending
            emails and conversing through WhatsApp and Facebook, right from your
            CRM system. Track and record the activities by keeping a timeline
            view of every interaction with your clients. Add, edit, and complete
            new sales activities on the go.
          </>
        ),
        img: getImage(featureEngage),
        alt: "Engage more customer with best CRM with social media integration",
      },
      {
        title: "Manage",
        description: (
          <>
            Manage your business with an efficient{" "}
            <a href="https://peppercloud.com/sales-pipeline">
              {" "}
              sales pipeline management
            </a>{" "}
            feature. Build multiple sales pipelines and customise stages in the
            pipelines as per your needs. Identify, add, and categorise new leads
            easily and quickly. Save time and team efforts by eliminating
            repetitive tasks. Stay on top of your activities and follow-ups with
            utmost convenience.
          </>
        ),
        img: getImage(featureManage),
        alt: "Manage business with sales pipeline management tool",
      },
      {
        title: "Automate",
        description: (
          <>
            With{" "}
            <a href="https://peppercloud.com/integration">
              {" "}
              powerful CRM system integrations
            </a>
            , sync your favourite tools to capture leads automatically, generate
            quotations, set up video meetings, and keep track of your processes
            and activities in real-time. Eliminate repetitive tasks with
            ultimate CRM capabilities. Automate your sales progress with the
            Pepper Cloud CRM tool.
          </>
        ),
        img: getImage(featureAutomate),
        alt: "Automate everything with powerful CRM system integrations",
      },
      {
        title: "Expand",
        description:
          "Increase sales referrals with the help of existing customers. Improve products and services with the help of your customers’ feedback. Discover opportunities to upsell and cross-sell to your existing customers with the help of existing customer information and actionable in-depth insights.\n",
        img: getImage(featureExpand),
        alt: "Expand business with Best Sales CRM",
      },
    ];

    return pipeline;
  }, []);

  const toggleVideoModal = () => setShowVideo((st) => !st);




  return (
    <React.Fragment>
      <Box  >
        <Container>
          <Grid alignItems="center" container justify="center" spacing={7}>
            <Grid item  md={5} sm={12}>


            </Grid>
            <Grid item md={8} sm={12}>
              <Box className={classes.homeSliderText}>
                <Box
                  alignItems="center"
                  display="flex"
                  height={24}
                  mb={2}
                  mt={0}
                >
                </Box>
                <HeaderTypography className={classes.TelegramIntegrationHeader}>
                  Telegram integration
                  </HeaderTypography>

                <HeaderTypography className={classes.syncAndSendHeader}>
                Win conversations on Telegram with CRM integration.
                </HeaderTypography>

                <ParagraphTypography className={classes.efficientlyManageTasks}>
                Elevate communication with native Telegram CRM integration. Connect with your customers in real-time on Telegram and effortlessly manage leads, conversions, and customer relationships through your CRM.
                </ParagraphTypography>


             <Grid
    component={Box}
    item
    lg={12}
    maxWidth="100%"
    md={12}
    paddingLeft="20px"
    sm={12}
  >
    {/* <Box className={classes.crmheadimgContainer}> */}
      <StaticImage
        alt="Telegram Hero"
        // className={classes.crmheadimg}
        placeholder="blurred"
        src="../images/Telegram.png"
        style={{ width: '100%', height: "auto" }} // Apply inline styles directly
      />
    {/* </Box> */}
  </Grid>

                <HeaderTypography className={classes.TelegramIntegrationFeatures}>
                Telegram integration features
                </HeaderTypography>

              <ParagraphTypography className={classes.customerCommunication}>
              Create and share instant reply templates to contacts.<br/>
              Create and manage Telegram groups within the CRM.<br/>
              Send and receive folders, documents, videos, audio files, GIFs, Excel, etc.<br/>
              Assign message threads to designated team members.
              </ParagraphTypography>


              <HeaderTypography className={classes.TelegramIntegrationFeatures}>
                Telegram + Pepper Cloud CRM
                </HeaderTypography>

                <ParagraphTypography className={classes.Parafeatures}>
                By seamlessly integrating your Telegram account with Pepper Cloud CRM, you create a direct link between your messages and the CRM system. This simplifies customer message handling, eliminating the need for third-party integrations. Pepper Cloud CRM offers a native Telegram connection, recognising the platform for its speed, user-friendliness, and security. The integration has the potential to empower your business to efficiently manage high message volumes, enhancing automation capabilities. Consequently, it streamlines communication, improves data access and management, boosts customer satisfaction, and boosts sales growth.
                </ParagraphTypography>

                <HeaderTypography className={classes.gmailIntegration}>
                How to integrate Telegram with Pepper Cloud CRM?
                </HeaderTypography>



                <ParagraphTypography className={classes.loginto}>
                Start by searching for BotFather on Telegram.<br />
                Send command /newbot and answer the following queries.<br />
                Give a name to the bot and provide a username.<br />
                Obtain its token by using the /mybots command.<br />
                Choose the bot and action.<br />
                Get the API token.<br />
                Create a Telegram channel in the CRM and paste the token.<br />
                Enter details and get Telegram listed on the Channels page.
                </ParagraphTypography>

                <span>
                <ParagraphTypography className={classes.NotUnderline}>For detailed steps, refer to the </ParagraphTypography>
                <ParagraphTypography className={classes.Underline}>Telegram setup guide.</ParagraphTypography>
                </span>


              </Box>
            </Grid>

          </Grid>
        </Container>
        <Box mb={2} mt={1}>
          <Divider light />
        </Box>
      </Box>

      <Box>
        <Box
          alignItems="center"
          component={Container}
          display="flex !important"
          flexDirection="column"
          my={{ md: 7 }}
          textAlign="center"
        >

        </Box>
      </Box>

    </React.Fragment>
  );
};

export const query = graphql`
  query {
    featureEngage: file(
      sourceInstanceName: { eq: "home-dynamic" }
      name: { eq: "feature-engage@2x" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 500, placeholder: BLURRED)
      }
    }
    featureManage: file(
      sourceInstanceName: { eq: "home-dynamic" }
      name: { eq: "feature-manage@2x" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 500, placeholder: BLURRED)
      }
    }
    featureExpand: file(
      sourceInstanceName: { eq: "home-dynamic" }
      name: { eq: "feature-expand@2x" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 500, placeholder: BLURRED)
      }
    }
    featureAutomate: file(
      sourceInstanceName: { eq: "home-dynamic" }
      name: { eq: "feature-automate@2x" }
    ) {
      childImageSharp {
        gatsbyImageData(height: 500, placeholder: BLURRED)
      }
    }
  }
`;

CrmTelegramIntegration.propTypes = {
  data: PropTypes.object,
};

export default CrmTelegramIntegration;
